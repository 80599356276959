import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const RegisterDevice: React.FC<RouteComponentProps> = (props) => {
  const [deviceName, setDeviceName] = useState('');
  const [isRequested, toggleRequested] = useState(false);

  useEffect(() => {
    localStorage.setItem('nanoID', nanoid());
  }, []);

  useEffect(() => {
    const unregisteredDeviceName = localStorage.getItem('deviceName');
    if (unregisteredDeviceName) {
      toggleRequested(true);
    } else {
      toggleRequested(false);
    }
  }, []);

  const sendRequest = useCallback(() => {
    localStorage.setItem('deviceName', deviceName);
    toggleRequested(true);
  }, [deviceName]);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Register Device</h1>
      {isRequested ? (
        'Requested'
      ) : (
        <>
          <input
            type="text"
            onChange={(e) => setDeviceName(e.target.value)}
            placeholder="Device name"
          />
          <button
            type="button"
            disabled={deviceName === '' || deviceName === 'undefined'}
            onClick={sendRequest}>
            Request Access
          </button>
        </>
      )}
      <br />
      <br />
      <button type="button" onClick={() => props.history.push('/')}>
        Refresh
      </button>
    </div>
  );
};

export default withRouter(RegisterDevice);
