import circle from '@turf/circle';
import { Howl, Howler } from 'howler';
import L from 'leaflet';
import React from 'react';
import { GeoJSON, Map, TileLayer, withLeaflet, ZoomControl, MapProps } from 'react-leaflet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { finish, foundSpot, newPosition, nextGameStep, showMiniGame } from '../GlobalActions';
import { calcIconSize } from '../helpers/icon';
import { Mixpanel } from '../lib/mixpanel';
import LocationEngine from '../LocationEngine/LocationEngine';
import LocateControl from '../ReactLeafletLocateControl';
import NoSleep from 'nosleep.js';
import StatusBar from '../components/status-bar';
import TrailInfo from '../components/trail-info';
import { State } from '../GlobalReducers';
import { Position } from '../types/types';
import { connect } from 'react-redux';
import { locateOptions, sizeSpot } from '../helpers/contant';
import MapMarkers from '../components/map-markers';
import { MiniGame } from '../components/mini-game/mini-game';
import { IMiniGame } from '../lib/@types/generated/contentful';

interface NavigationProps extends MapProps {
  newPosition: (position: GeolocationPosition) => void;
}

interface NavigationState {
  orientation?: number;
}

class Navigation extends React.Component<NavigationProps, NavigationState> {
  currentWatcher: number = 0;
  constructor(props: NavigationProps | Readonly<NavigationProps>) {
    super(props);
    this.state = {
      orientation: undefined,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setupPositionWatcher();
    this.setupOrientationWatcher();
    this.forceUpdate();
  }

  setupPositionWatcher() {
    this.handlePositionUpdate = this.handlePositionUpdate.bind(this);
    const geo_options = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    };

    this.currentWatcher = navigator.geolocation.watchPosition(
      this.handlePositionUpdate,
      null,
      geo_options
    );
  }

  setupOrientationWatcher() {
    window.addEventListener(
      'compassneedscalibration',
      function () {
        alert(
          'Sorry but your compass seems to need calibration. Please calibrate and get back here.'
        );
      },
      false
    );
  }

  handlePositionUpdate(position: GeolocationPosition) {
    LocationEngine.new_position(position);
    this.props.newPosition(position);
    (this.props as NavigationProps & { leaflet: { map: any } }).leaflet.map.panTo(
      { lat: position.coords.latitude, lng: position.coords.longitude },
      { animate: true, duration: 0.3 }
    );
  }

  componentWillUnmount() {
    window.navigator.geolocation.clearWatch(this.currentWatcher);
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (/*, ownProps*/) => {
  return {};
};

const mapDispatchToProps = {
  newPosition,
};

// DIFERENT COMPONENT ========================================

interface GamePageProps extends RouteComponentProps {
  track: any; // replace with the actual type
  collectablePoints: any; // replace with the actual type
  collectableIcons: any; // replace with the actual type
  theme: any; // replace with the actual type
  tenantId: string;
  sessionId: string;
  collectedSpots: any; // replace with the actual type
  foundSpot: (area: any) => void; // replace 'any' with the actual type
  showMiniGame: () => void;
  finish: () => void;
  nextGameStep: () => void;
  newPosition: (position: Position) => void;
  totalDistanceMeters: number;
  miniGames: IMiniGame[];
}

interface GamePageState {
  areas: any[]; // replace 'any' with the actual type
  signs: any[]; // replace 'any' with the actual type
  isShowCollectables: boolean;
  isShowFinishPoint: boolean;
  startPoint?: any; // replace 'any' with the actual type
  endPoint?: any; // replace 'any' with the actual type
  offsetZoom: number;
  debugNum: number;
  noSleep: NoSleep;
  finishSound?: Howl;
}

const LeafletNavigation = withLeaflet(
  connect(mapStateToProps, mapDispatchToProps)(Navigation as any)
);

class GamePage extends React.Component<GamePageProps, GamePageState> {
  constructor(props: GamePageProps | Readonly<GamePageProps>) {
    super(props);
    this.state = {
      areas: [],
      signs: [],
      isShowCollectables: false,
      isShowFinishPoint: false,
      startPoint: undefined,
      endPoint: undefined,
      offsetZoom: 17,
      debugNum: 0,
      noSleep: new NoSleep(),
    };
  }

  componentDidMount() {
    const localstorage_isShowCollectables = localStorage.getItem('isShowCollectables');
    if (localstorage_isShowCollectables === 'true') {
      this.setState({
        isShowCollectables: true,
      });
    }
    this.state.noSleep.enable();
    Mixpanel.track('start-walk', {
      TenantId: this.props.tenantId,
      SessionId: this.props.sessionId,
      DateTime: new Date().toISOString(),
    });
    for (var i = 0; i < this.props.track.fields.collectablePoints.length; i++) {
      this.addSpot(i, sizeSpot.collectableItem);
    }
    this.addSpotStartAndFinish(
      'startPoint',
      this.props.track.fields.startPoint,
      sizeSpot.startAndFinish
    );
    this.addSpotStartAndFinish(
      'endPoint',
      this.props.track.fields.finishPoint,
      sizeSpot.startAndFinish
    );
    window.addEventListener('focus', () => Howler.mute(false));
    window.addEventListener('blur', () => Howler.mute(true));
  }

  componentWillUnmount() {
    console.log('game componentWillUnmount');
    this.state.noSleep.disable();
    LocationEngine.removeAllAreas();
  }

  playerEnteredSpotCallback(area: { spotId: number; gameType: string }) {
    if (
      area.gameType === 'ATTENTION' ||
      area.gameType === 'MEMORY' ||
      area.gameType === 'PROBLEM_SOLVING'
    ) {
      this.props.showMiniGame();
    } else {
      this.playCollectableItemSound();
    }
    const spotId = area.spotId;
    this.removeSpot(spotId);
    this.props.foundSpot(area);
  }

  removeSpot(spotId: number) {
    const area = this.state.areas.find((item) => item.area.spotId === spotId);
    LocationEngine.removeArea(area.area);
    const areas = this.state.areas.filter((a) => a.area.spotId !== spotId);
    this.setState({ areas: areas });
    return spotId;
  }

  addSpot(spotIndex: number, sizeSpot: number) {
    const spot = this.props.track.fields.collectablePoints[spotIndex];
    const iconAsset =
      this.props.collectableIcons[Math.floor(Math.random() * this.props.collectableIcons.length)];
    const area: any = circle([spot.lon, spot.lat], sizeSpot, {
      steps: 64,
      units: 'kilometers',
    });
    area.spotId = spotIndex;
    area.iconUrl =
      spot.type && spot.type !== 'collectible'
        ? this.props.miniGames.find((mg) => mg.fields.gameType === spot.type)?.fields.icon.fields
            .file.url
        : iconAsset.fields.file.url;
    area.iconId = iconAsset.sys.id;
    area.gameType = spot.type ? spot.type : 'collectable';
    LocationEngine.addArea(area, this.playerEnteredSpotCallback.bind(this));
    console.log('Binding playEntered');
    this.setState((prevState) => ({
      areas: [...prevState.areas, { area: area, spot: spot }],
      signs: [...prevState.signs, { area: area, spot: spot }],
    }));
  }

  addSpotStartAndFinish(type: string, point: { lon: number; lat: number }, sizeSpot: number) {
    // circle's size is radius in meters
    // icon size is size in pixels
    const area = circle([point.lon, point.lat], sizeSpot, {
      steps: 64,
      units: 'kilometers',
    });

    LocationEngine.addArea(
      area,
      () => {
        console.log('area callback', type);
        if (type === 'startPoint') {
          if (this.state.isShowCollectables) return;
          const startSound = new Howl({
            src: [this.props.theme.fields.startSoundAndVoiceOver.fields.sound.fields.file.url],
            preload: true,
            loop: false,
            volume: 0.75,
            autoplay: false,
          });
          if (this.props.theme.fields.startSoundAndVoiceOver.fields.voiceOver) {
            const startVoiceover = new Howl({
              src: [
                this.props.theme.fields.startSoundAndVoiceOver.fields.voiceOver.fields.file.url,
              ],
              preload: true,
              loop: false,
              volume: 0.75,
              autoplay: false,
            });
            startVoiceover.play();
            startVoiceover.on('end', () => startSound.play());
          } else {
            startSound.play();
          }
          if ('vibrate' in window.navigator) window.navigator.vibrate([125, 75, 125, 75, 125]);
          this.setState({
            isShowCollectables: true,
          });
        } else {
          // User at finish point
          if (this.state.isShowFinishPoint) {
            Howler.stop();
            const finishSound = new Howl({
              src: [this.props.theme.fields.finishSoundAndVoiceOver.fields.sound.fields.file.url],
              preload: true,
              loop: false,
              volume: 0.75,
              autoplay: false,
            });
            if (this.props.theme.fields.finishSoundAndVoiceOver.fields.voiceOver) {
              const finishVoiceover = new Howl({
                src: [
                  this.props.theme.fields.finishSoundAndVoiceOver.fields.voiceOver.fields.file.url,
                ],
                preload: true,
                loop: false,
                volume: 0.75,
                autoplay: false,
              });
              finishVoiceover.play();
              finishVoiceover.on('end', () => finishSound.play());
            } else {
              finishSound.play();
            }
            finishSound.play();
            this.props.nextGameStep();
            if (this.state.finishSound) this.state.finishSound.play();
            Mixpanel.track('finish-walk', {
              TenantId: this.props.tenantId,
              SessionId: this.props.sessionId,
              DateTime: new Date().toISOString(),
              DistanceOnMeter: this.props.totalDistanceMeters,
            });
            this.props.finish();
            this.props.history.push('/finish');
          }
        }
      },
      undefined,
      () => {
        if (type === 'startPoint') {
          console.log('Outside of area');
          if (!this.state.isShowCollectables) return;
          this.setState({
            isShowFinishPoint: true,
          });
        }
      }
    );

    this.setState((prevState) => ({
      ...prevState,
      [type]: {
        area,
      },
    }));
  }

  playCollectableItemSound() {
    const collectableItemSound = new Howl({
      src: [
        this.props.theme.fields.pickupSoundAndVoiceOver[
          Math.floor(Math.random() * this.props.theme.fields.pickupSoundAndVoiceOver.length)
        ].fields.sound.fields.file.url,
      ],
      preload: true,
      loop: false,
      volume: 0.75,
    });
    collectableItemSound.play();
    if ('vibrate' in window.navigator) window.navigator.vibrate([125, 75, 125, 75, 125]);
    console.log('play sound');
  }

  icon(iconUrl: any, zoomLevel: number, size: number) {
    console.log('Icon: ', zoomLevel, size);
    const calculatedSize = calcIconSize(zoomLevel, size);
    return new L.Icon({
      iconUrl: iconUrl,
      iconAnchor: [calculatedSize / 2, calculatedSize / 2],
      iconSize: new L.Point(calculatedSize, calculatedSize),
    });
  }

  render() {
    return (
      <>
        <StatusBar areas={this.state.areas} collectedSpots={this.props.collectedSpots} />
        <Map
          id="map"
          onclick={(e) => {
            if (this.state.debugNum < 3) return;
            const position = {
              coords: {
                longitude: e.latlng.lng,
                latitude: e.latlng.lat,
              },
            } as any;
            console.log(position);
            LocationEngine.new_position(position);
            this.props.newPosition(position);
          }}
          zoomControl={false}
          maxZoom={17}
          bounds={[
            this.props.track.fields.startPoint,
            this.props.track.fields.finishPoint,
            this.props.track.fields.collectablePoints.slice(0, 1),
          ]}
          preferCanvas={true}
          style={{ width: '100vw', height: '100vh' }}
          onload={() => {
            console.log('Load');
          }}
          onzoomend={(e) => {
            console.log('Changing zoom level to: ', e.target._zoom);
            this.setState({
              offsetZoom: e.target._zoom,
            });
          }}>
          <ZoomControl position="topright" />
          <LeafletNavigation />
          <TileLayer
            attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            id="mapbox.streets"
          />
          <LocateControl options={locateOptions} startDirectly />

          {this.state.isShowCollectables &&
            this.state.signs.map((sign, index) => <GeoJSON key={index} data={sign.area} />)}

          {this.state.startPoint && (
            <GeoJSON
              onclick={() => {
                this.setState((prevState) => ({
                  debugNum: prevState.debugNum + 1,
                }));
              }}
              data={this.state.startPoint.area}
              style={{
                color: '#cde400',
              }}
            />
          )}
          {this.state.endPoint && this.state.isShowFinishPoint && (
            <GeoJSON
              data={this.state.endPoint.area}
              style={{
                color: '#d33800',
              }}
            />
          )}
          <MapMarkers
            areas={this.state.areas}
            isShowCollectables={this.state.isShowCollectables}
            debugNum={this.state.debugNum}
            offsetZoom={this.state.offsetZoom}
            icon={this.icon}
          />
        </Map>
        <TrailInfo />
        <MiniGame />
      </>
    );
  }
}

const mapStateToPropsGamePage = (state: State /*, ownProps*/) => {
  return {
    track: state.track, // Add type assertion to ITrack
    collectablePoints: (state.track as any | undefined).collectablePoints,
    collectableIcons: state.theme?.fields.collectableIcons,
    theme: state.theme,
    tenantId: state.tenant?.sys.id,
    sessionId: state.sessionId,
    collectedSpots: state.collectedSpots,
    totalDistanceMeters: state.totalDistanceMeters,
    miniGames: state.miniGames,
  };
};

const mapDispatchToPropsGamePage = {
  foundSpot,
  finish,
  nextGameStep,
  newPosition,
  showMiniGame,
};

export default withRouter(
  connect(mapStateToPropsGamePage, mapDispatchToPropsGamePage)(GamePage as any)
);
