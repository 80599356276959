import React, { useEffect } from 'react';
import { nanoid } from 'nanoid';
import {
  checkIfCustomerExists,
  checkIfNanoIDExists,
  checkIfTenantExists,
  getNanoID,
  getSelectedTenant,
} from '../localstorage';
import { getDeviceEntry } from '../lib/contentful-management-api';
import { useHistory } from 'react-router';
import { getTenantById } from '../lib/contentful';
import Spinner from '../components/spinner/spinner';

const Redirect = () => {
  const history = useHistory();
  useEffect(() => {
    const asyncFunc = async () => {
      localStorage.removeItem('isShowCollectables');
      const isCustomerExist = checkIfCustomerExists();
      const isTenantExist = checkIfTenantExists();
      const nanoID = checkIfNanoIDExists() ? getNanoID() : nanoid();

      if (!isCustomerExist || !isTenantExist) {
        localStorage.setItem('nanoID', nanoID);
        history.push('/login');
      } else {
        const selectedTenant = getSelectedTenant();
        const latestTenant = await getTenantById(selectedTenant.sys.id);
        if (!latestTenant.fields.deviceList) throw new Error('No device list found');
        const customerDevices = latestTenant.fields.deviceList.map(
          (device) => device.fields.deviceId
        );
        const deviceEntryId = latestTenant.fields.deviceList.find(
          (device) => device.fields.deviceId === nanoID
        )?.sys.id;
        if (customerDevices.includes(nanoID) && deviceEntryId) {
          await getDeviceEntry(deviceEntryId)
            .then(() => {
              history.push('/start');
            })
            .catch(() => {
              history.push('/login');
            });
        } else {
          localStorage.setItem('nanoID', nanoID);
          history.push('/login');
        }
      }
    };

    asyncFunc();
  }, [history]);

  return <Spinner fullScreen />;
};

export default Redirect;
